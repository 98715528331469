import axios from 'axios'
import * as u from '@/util/util'
import * as c from '@/util/config'
import {Message, MessageBox, alert} from 'element-ui'
import router from '@/router/permission'


const service = axios.create({
    baseURL: c.system.apiUrl,
    // baseURL: "/api", // api 的 base_url  use proxy table
    // timeout: 5000, // request timeout
    timeout: 10000 // 请求超时10秒
})

// request interceptor
service.interceptors.request.use(
    config => {
        // Do something before request is sent
        if (u.getToken()) {
            // 让每个请求携带token-- ['X_ACCESS_TOKEN']为自定义key 请根据实际情况自行修改
            config.headers['X-Access-Token'] = u.getToken();
        }
        return config
    },
    error => {
        // Do something with request error
        Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(response => {
        const res = response.data
        console.log(res)
        if (res.code == 200 || res.code == 1) {
            return response.data
        } else if (res.code === 401) {
            //清楚token里面的信息
            u.clearSession();
            router.push("/login");
            return Promise.reject()
        } else if (res.code === 302) {
            router.push("/login");
            return Promise.reject()
        } else {
            Message({
                message: res.msg,
                type: 'error',
                duration: 5 * 1000
            })
            return Promise.reject()
        }
    },
    (error) => {
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service
