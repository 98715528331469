import * as u from '@/util/util'

/**
 * http请求工具类
 *
 */
export class HttpService {
    /**
     * get方法
     * @param url：接口地址
     * @param params：参数
     * @returns {*}
     */
    get(url, params){
        return u.get(url,params);
    }

    /**
     * post请求
     * @param url：接口地址
     * @param params：参数
     * @returns {*}
     */
    post(url, params){
        return u.post(url,params);
    }

    /**
     * put请求
     * @param url：接口地址
     * @param params：参数
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    put(url, params){
        return u.put(url, params)
    }

    /**
     * delete请求
     * @param url：接口地址
     * @param params：参数
     * @returns {*}
     */
    del(url, params){
        return u.del(url, params);
    }


    /**
     * axios请求分装
     * @param options：自定义axios参数
     * @returns {*}
     */
    reqForm(options){
        return u.reqForm(options);
    }


    /**
     * 上传文件
     * @param formData
     * @returns {*}
     */
    uploadFile(formData){
        return u.uploadFile(formData);
    }
}
