
import Vue from 'vue'
import Vuex from 'vuex'
import * as u from '@/util/util'
//webSocket
import webSocket from './modules/webSocket';
//打开的标签页
import menuTab from './modules/menuTab';
// 挂载vuex
Vue.use(Vuex)


const store =new Vuex.Store({
  // 全局属性变量
  state: {
    userInfo:u.getSession("userInfo"),
  },
  // 全局同步方法, 调用方法,this.$store.commit("xxx")
  mutations: {
    setUserInfo(state,data){
      state.userInfo = data
    }

  },
  // 异步方法 调用方法,this.$store.dispatch("xxx")
  actions: {
  },
  // Vuex属性计算,在视图里面当变量使用
  getters: {
  },
  // 模块化注册
  modules: {
    webSocket,
    menuTab
  }
})

export default store
