import Vue from 'vue'
import App from './App'
import router from './router/permission'
import ElementUI from 'element-ui';
import Avue from '@smallwei/avue';
import axios from './axios/index'
import VueAxios from 'vue-axios'
import 'element-ui/lib/theme-chalk/index.css';
import '@smallwei/avue/lib/index.css';
import store from './store/index.js'
import * as filters from '@/filter/index'
import * as util from './util/util'
import * as config from './util/config'
import {HttpService}  from './util/HttpService'
import '@/css/core.css';

/*全局过滤器*/
Object.keys(filters).forEach(key=>{
  Vue.filter(key,filters[key])
})

// 挂载到Vue实力上，全局可通过this.$store进行调用
Vue.prototype.$store = store
//将工具类挂载在vue上
Vue.prototype.$util = util
Vue.prototype.$config = config
Vue.prototype.$HttpService = new HttpService();
Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.use(Avue);
Vue.use(VueAxios, axios)
Vue.prototype.$axios = axios
window.axios = axios
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  render: h => h(App)
})
