import { Message } from "element-ui"; // 弹吐司
export default {
  state: {
    ws: null,
  },
  mutations: {
    // websocket
    updateWebSocket(state, ws) {
      state.ws = ws;
    }
  },
  actions: {
    //打开socket连接
    openWebSocket({ commit, state }, {key ,fun}) {
      if (!key) {
        throw "请传消息key";
      }
      if (!fun) {
        throw "请传入消息接受回调函数";
      }

      if(typeof(WebSocket) == "undefined") {
        Message({
          type: "warning",
          message: "您的浏览器不支持 WebSocket!请更换浏览器。"
        });
      }else {
        var socketUrl="ws://192.168.3.229/socketServer/"+key;
        //关闭之前的连接
        if(state.ws!=null){
          state.ws.close();
          state.ws=null;
        }
        const ws=new WebSocket(socketUrl)
        commit("updateWebSocket", ws);
        //打开事件
        ws.onopen = function() {
          console.log("websocket已打开");
        };
        //获得消息事件
        ws.onmessage = function(msg) {
          fun(JSON.parse(msg.data).data);
        };
        //关闭事件
        ws.onclose = function() {
          console.log("websocket已关闭");
        };
        //发生了错误事件
        ws.onerror = function() {
          console.log("websocket发生了错误");
        }
      }
    },

    // 关闭socket连接
    closeWebSocket({ commit, state }) {
      if (state.ws!=null){
        state.ws.close();
        state.ws.onclose = e => {
          console.log("webSocket连接关闭");
          commit("updateWebSocket", null);
        };
      }
    }
  }
}
