import * as c from '@/util/config'
import * as u from '@/util/util'

export default {
  state: {
    //打开过的弹框
    openMenu: u.getSession(c.sessionKey.OPEN_MENU) == null ? [c.HOME_PAGE] : u.getSession(c.sessionKey.OPEN_MENU),
    //当前打开的页面
    thisMenu:u.getSession(c.sessionKey.THIS_MENU_KEY)==null?c.HOME_PAGE.id:u.getSession(c.sessionKey.THIS_MENU_KEY),
  },
  mutations: {
    //设置tab页
    setOpenMenu(state, data) {
      state.openMenu = data
      u.setSession(c.sessionKey.OPEN_MENU, state.openMenu);
    },
    //新增一个tab页
    addOpenMenu(state, data) {
      state.openMenu.push(data);
      u.setSession(c.sessionKey.OPEN_MENU, state.openMenu);
    },

    //按下标删除一个tab页
    delOpenMenu(state, index) {
      state.openMenu.splice(index, 1);
      u.setSession(c.sessionKey.OPEN_MENU, state.openMenu);
    },

    setThisMenu(state, data) {
      u.setSession(c.sessionKey.THIS_MENU_KEY, data);
      state.thisMenu = data
    }
  },
  actions: {
    //关闭所有菜单
    closeAll({ commit, state }) {
      commit("setOpenMenu", [state.openMenu[0]]);
      //设置打开的弹框
      u.setSession(c.sessionKey.OPEN_MENU,state.openMenu);

    },
    //关闭左边
    closeLeft({ commit, state },menuId) {
      //找到当前菜单所在下标
      let index =  state.openMenu.findIndex(x=>x.id === menuId);
      state.openMenu.splice(1,index-1);
      //设置打开的弹框
      u.setSession(c.sessionKey.OPEN_MENU,state.openMenu);
    },
    //关闭右边
    closeRight({ commit, state },menuId) {
      //找到当前菜单所在下标
      let index =  state.openMenu.findIndex(x=>x.id === menuId);
      state.openMenu.splice(index+1);
      //设置打开的弹框
      u.setSession(c.sessionKey.OPEN_MENU,state.openMenu);
    },
    //关闭其他
    closeOther({ commit, state,dispatch  },menuId) {
      //找到当前菜单所在下标
      let index =  state.openMenu.findIndex(x=>x.id === menuId);
      state.openMenu.splice(1,index-1);
      index =  state.openMenu.findIndex(x=>x.id === menuId);
      state.openMenu.splice(index+1);
      //设置打开的弹框
      u.setSession(c.sessionKey.OPEN_MENU,state.openMenu);
    },
  }
}
