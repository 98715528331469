import * as u from '@/util/util'
import {sessionKey} from '@/util/config'
import {router, staticRouter} from './index.js'


var whiteList = ['/login',"/"]

//获取路由树
function getRouter() {
   let tree= u.getSession(sessionKey.SYS_MENU_TREE);
   return buildRouterList(tree)
}


//构建路由集合
function buildRouterList(tree) {
  let routerList=[];
  tree.forEach(x=>{
    if(x.children!=null && x.children.length>0){
      buildRouterList(x.children).forEach(y=>{
        routerList.push(y)
      })
    }
    const component = 'view' + x.path
    if(x.type=="1"){
      routerList.push({
        path: x.path,
        name: x.name,
        meta: {
          title: x.name,
          keepAlive: x.cache=='Y'?true:false
        },
        component(resolve) {
          require([`../${component}.vue`], resolve)
        },
      })
    }
  })
  return routerList;
}

//  拦截
var intercept = false; // 页面刷新货初次加载 才会执行这个变量

/*路由守卫*/
router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title
  //判断当前用户token是否存在
  let token=u.getToken();
  //判断跳转的是否是不需要登录
  if(whiteList.indexOf(to.path)==-1){
    //需要登录
    //判断token是否存在
    if(token==null || token==''){
        //没有登录切换登录页面
      next("/login")
    } else{
      //设置动态
      // 当 qq 为false 表示 刷新了 或 第一次加载
      if (!intercept) {
        intercept = true; // 先让他变 true 不然跳转页面时会死循环
        getRouter().forEach(x => {
          const component = 'view' + x.path
          router.options.routes[0].children.push({
            path: x.path,
            name: x.name,
            meta:x.meta,
            component(resolve) {
              require([`../${component}.vue`], resolve)
            },
          });
        })
        router.addRoutes(router.options.routes);
        next(to.path);
      }else{
        next();
      }
    }
  }else{
    //不需要登录
    next();
  }
})
export default router;
