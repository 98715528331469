import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

//静态路由
export const staticRouter = [
  {
    path: '/admin',
    name: 'admin',
    redirect: '/sy',
    meta: {keepAlive:true},
    component: () => import( '@/view/admin'),
    children: [
      {
        path: '/sy',
        name: '首页',
        meta:{
          title:'首页'
        },
        component: () => import('@/view/sy.vue')
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: "登录-注册"
    },
    component: () => import( '@/view/login')
  },
  {
    path: '/',
    name: 'index',
    meta: {
      title: "首页"
    },
    component: () => import( '@/view/home/index')
  },
  {
    path:'*',
    meta: {
      title: "404"
    },
    component: () => import( '@/view/404')
  }
]

const createRouter = () => new Router({
  routes:staticRouter
})

export const router = createRouter()




