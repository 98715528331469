/*定义图标*/
const iconList = [
    {
        label: 'ElementUI',
        list: [
            "el-icon-edit", "el-icon-delete", "el-icon-plus",
            'el-icon-platform-eleme', 'el-icon-eleme', 'el-icon-delete-solid',
            'el-icon-delete', 'el-icon-s-tools', 'el-icon-setting', "el-icon-user-solid",
            "el-icon-user", "el-icon-phone", "el-icon-phone-outline", "el-icon-more",
            "el-icon-more-outline", "el-icon-star-on", "el-icon-star-off", "el-icon-warning",
            "el-icon-warning-outline", "el-icon-circle-plus-outline", "el-icon-remove-outline", "el-icon-circle-check",
            "el-icon-circle-close", "el-icon-minus", "el-icon-plus", "el-icon-check", "el-icon-close",
            "el-icon-picture", "el-icon-picture-outline", "el-icon-upload", "el-icon-upload2", "el-icon-download",
            "el-icon-s-promotion", "el-icon-s-home", "el-icon-s-grid", "el-icon-menu", "el-icon-s-data",
            "el-icon-s-custom", "el-icon-share", "el-icon-caret-left", "el-icon-caret-right", "el-icon-caret-bottom",
            "el-icon-caret-top", "el-icon-back", "el-icon-right", "el-icon-bottom", "el-icon-top",
            "el-icon-folder", "el-icon-folder-opened", "el-icon-chat-line-round", "el-icon-chat-dot-round", "el-icon-map-location",
            "el-icon-view", "el-icon-refresh-right", "el-icon-cpu", "el-icon-timer", "el-icon-video-play", "el-icon-video-pause"
        ]
    },
]


const HOME_PAGE = {     //首页定义
    id: "-1",
    name: "首页",
    path: "/sy"
}


//sessionkey的定义
const sessionKey = {
    THIS_MENU_KEY: "thisMenu",                //当前页的key值
    USER_INFO: "userInfo",                    //用户信息的key值
    SYS_MENU_TREE: "sysMenuTree",             //用户菜单树的key值
    SYS_MENU_LIST: "sysMenuList",             //用户菜单集合的key值
    SYS_PERMISSIONS: "sysPermissions",        //用户权限集合
    OPEN_MENU: "openMenu",                    //打开过的菜单
    TOKEN: "token",                           //token的key值
    MENU_STATE: "menuState",                  //菜单状态变化key
}


//系统配置
const system = {
    apiUrl: "https://www.hejialun.online/api"
    // apiUrl: "http://127.0.0.1:8090"
}


//钉钉配置
const dd = {
    corpId: "ding52d9c9b22426a2e0acaaa37764f94726",
}


/**
 * 支持指定操作的文件类型
 * @type {{view: string[], edit: string[]}}
 */
const supportFile = {
    //支持在线预览的文件
    view: ["docx", "ppt", "pdf", "txt", "xlsx", "jpg", "jpeg", "png"],
    //支持在线编辑的文件
    edit: ["docx", "ppt", "xlsx"],
    //支持解压的文件类型
    decompression: ["zip"],
    //浏览器预览类型
    windowView: ["pdf", "jpg", "jpeg", "png"],
    //图片类型
    imgType: ["jpg", "jpeg", "png"],
    //支持转pdf的文件
    toPdf:["docx", "ppt", "pdf", "txt", "xlsx", "jpg", "jpeg", "png"],
    //图片图标映射map
    fileToIconPngMap: {
        "docx": "docx.png",
        "doc": "docx.png",
        "xlsx": "xlsx.png",
        "xls": "xlsx.png",
        "pdf": "pdf.png",
        "ppt": "ppt.png",
        "ios": "ios.png",
        "apk": "apk.png",
        "html": "html.png",
        "mp3": "mp3.png",
        "mp4": "mp4.png",
        "txt": "txt.png",
        "zip": "zip.png",
        "rar": "zip.png",
    },
}


export {
    HOME_PAGE,
    iconList,
    sessionKey,
    dd,
    system,
    supportFile
}
